import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLock, faLockOpen, faCheck, faPencil, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faLock)
library.add(faLockOpen)
library.add(faCheck)
library.add(faPencil)
library.add(faTrash)
library.add(faX)

const app = createApp(App)

app.config.globalProperties.goBack = function () {
    this.$router.back();
};

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store)
app.use(router)
app.mount('#app')
