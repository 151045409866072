<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css">

  <div class="topbar">
    <h1>
      <span class="badge badge-light">Merves Rätsel</span>
    </h1>
    <nav>
      <router-link :to="{ name: 'raetsel_ueberblick' }">
        <button class="button btn btn-light">Übersicht</button>
      </router-link>
      <button class="button btn btn-light" @click="zumAktuellesRaetsel">Aktuelles Rätsel</button>
    </nav>
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  /* color: #ffffff; */
}

nav a.router-link-exact-active {
  color: #ffffff;
}

.button {
  margin: 5px;
}

.topbar {
  margin-bottom: 25px;
  background-color: #3498db;
  color: #1391da;
}

.topbar h1 {
  padding-bottom: 4;
}
</style>

<script>
export default {
  methods: {
    zumAktuellesRaetsel() {
      console.log('Lade Rätsel neu!')
      let url = process.env.VUE_APP_BACKEND_URL + "raetsel/aktuell";
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Fehler beim Abrufen der Daten: ' + response.statusText);
          }
          return response.json()
        })
        .then(responseData => {
          console.log('ROUTE ZU', responseData)
          this.$router.push({ name: 'raetsel', params: { id: responseData } });
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  }
};
</script>
