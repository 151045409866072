<template>
  <div class="home">
    <h1>
      Hallo Merve!
    </h1>
    <h4>
      Ich habe einige Rätsel verschiedener Themen für dich vorbereitet.
    </h4>
    <h4>
      Du kannst immer erst ein Rätsel lösen, wenn du das vorige gelöst hast.
    </h4>
    <h4>
      Die Rätsel erwarten immer nur eine einzige Antwort
      (Groß- und Kleinschreibung sind egal) und werden immer
      schwieriger und komplizierter je weiter du kommst.
    </h4>
    <h4>
      Bei Problemen kannst du natürlich auch gerne deine Freunde um Hilfe bitten.
    </h4>
    <h4>
      Wenn du nach drei Tagen bei einem Rätsel nicht weiterkommen solltest,
      wird ein Hinweis eingeblendet.
    </h4>
    <h4>
      Es gibt maximal zwei Hinweise pro Rätsel und du bekommst keine weitere Hilfe von mir (bin aber bestechlich).
    </h4>
    <h4>
      Viel Spaß wünsche ich dir / euch!
    </h4>
  </div>
</template>

<style scoped></style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StartView',
  components: {
  },
});
</script>
